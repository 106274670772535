<template>
  <div data-qa="review_phone_confirmation">
    <VBtn
      v-if="userData.isPhoneConfirmedByCall"
      block
      depressed
      class="mb-4"
      color="primary"
      data-qa="review_send_button"
      @click="handleClickSendReview"
    >
      <VProgressCircular
        v-if="isSomeImageSending"
        data-qa="review_send_button_progress"
        color="white"
        size="22"
        width="3"
        indeterminate
      />
      <template v-else>
        Отправить отзыв
      </template>
    </VBtn>
    <div
      class="ui-text ui-text_body-2 ui-kit-color-text-secondary text-center"
      data-qa="review_send_policy"
    >
      <template
        v-if="commonData.isRussiaDomain"
      >
        Отправляя отзыв, вы даёте согласие на
        <a
          target="_blank"
          href="/info/privacy-policy/"
          class="b-link b-link_underline_hover b-link_color_neur-blue d-inline"
        >обработку персональных данных</a>, принимаете
        <a
          target="_blank"
          href="/info/terms-of-use/"
          class="b-link b-link_underline_hover b-link_color_neur-blue d-inline"
        >пользовательское соглашение</a> и
        <template v-if="isSelectedLpuAddressInPrs">
          условия программ
          <a
            target="_blank"
            href="/info/authors-protection/"
            class="b-link b-link_underline_hover b-link_color_neur-blue d-inline"
          >«Защита авторов отзывов»</a>
          и
          <a
            target="_blank"
            href="/static/_v1/pd/documents/pdf/Регламент_Сила_отзыва_для_пациентов.pdf"
            class="b-link b-link_underline_hover b-link_color_neur-blue d-inline"
          >«Сила отзыва»</a>,
        </template>
        <template v-else>
          <a
            target="_blank"
            href="/info/authors-protection/"
            class="b-link b-link_underline_hover b-link_color_neur-blue d-inline"
          >условия программы защиты авторов отзывов</a>,
        </template>

        а также подтверждаете получение согласия третьих лиц на&nbsp;публикацию их персональных данных
      </template>
      <template
        v-else
      >
        Отправляя отзыв, вы даёте согласие на
        <a
          target="_blank"
          href="/info/privacy-policy/"
          class="b-link b-link_underline_hover b-link_color_neur-blue d-inline"
        >обработку персональных данных</a> и принимаете
        <a
          target="_blank"
          href="/info/terms-of-use/"
          class="b-link b-link_underline_hover b-link_color_neur-blue d-inline"
        >пользовательское соглашение</a>, а также подтверждаете получение
        согласия третьих лиц на&nbsp;публикацию их персональных данных
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import {
  useUserDataStore,
  useReviewImagesStore,
  useReviewFormDataStore,
  useReviewPageDataStore,
} from 'components/common/ReviewPage/stores'

export default {
  name: 'ReviewPhoneConfirmation',
  computed: {
    ...mapState(useUserDataStore, [
      'userData',
    ]),
    ...mapState(useReviewFormDataStore, [
      'isValidForm',
      'isSelectedLpuAddressInPrs',
    ]),
    ...mapState(useReviewImagesStore, [
      'isSomeImageSending',
    ]),
    ...mapState(useReviewPageDataStore, [
      'commonData',
    ]),
  },
  methods: {
    ...mapActions(useReviewFormDataStore, [
      'sendReviewFormData',
      'validateForm',
    ]),
    async handleClickSendReview() {
      await this.validateForm()

      if (this.isValidForm) {
        await this.sendReviewFormData()
      }
    },
  },
}
</script>
