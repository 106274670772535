<template>
  <div
    data-qa="review-success-prs"
    class="review-success-prs"
  >
    <VCard
      class="d-flex d-flex flex-column align-center ui-kit-color-text py-8"
      :class="isMobile ? 'px-4' : 'px-6'"
      elevation="1"
      rounded="xl"
    >
      <VIcon
        class="ui-icon_type_40 ui-kit-bg-success rounded-md"
        color="uiKitBgGray0"
      >
        ui-icon-done-40
      </VIcon>
      <div class="ui-text ui-text_h5 mt-3">
        Отзыв на проверке!
      </div>
      <div class="ui-text ui-text_body-1 mt-3">
        Это займёт 1–3 дня.
      </div>
      <div class="ui-text ui-text_body-1 text-center mt-3">
        Отслеживать статус отзыва и получить промокод после его публикации можно
        в&nbsp;МедТочке — личном кабинете пациента.
      </div>
      <VBtn
        v-if="isMobile"
        data-qa="review-success-prs_download-btn"
        href="https://4133365.redirect.appmetrica.yandex.com/?appmetrica_tracking_id=821619298169566105"
        class="mt-4 ui-kit-bg-bg-primary"
        color="primary"
        depressed
        text
      >
        Скачать МедТочку
      </VBtn>
      <div
        v-else
        class="review-success-prs__qr-wrapper"
      >
        <VImg
          src="/static/_v1/pd/qr/review/medtochka-app.png"
          width="182"
          height="182"
        />
        <div class="ui-kit-color-text-secondary ui-text ui-text_body-1 ml-4">
          Наведите камеру и&nbsp;скачайте приложение МедТочка
        </div>
      </div>
    </VCard>
    <div
      class="py-6"
      :class="isMobile ? 'px-4' : 'px-6'"
    >
      <div class="ui-text ui-text_body-1 ui-kit-color-text-secondary">
        Иногда клиники заказывают отзывы. Если вы стали участником создания заказного отзыва как автор,
        поделитесь с нами, а мы вас за это вознаградим.
      </div>
      <div class="mt-2">
        <a
          href="/info/rate-fraud-free-rules/"
          target="_blank"
          class="ui-text ui-text_body-1 ui-kit-color-text-secondary text-decoration-underline"
        >Подробнее</a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const isMobile = window.MOBILE_VERSION
</script>

<style lang="scss" scoped>
@import '~www/themes/doctors/common/variables';

.review-success-prs {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 415px;
  padding-bottom: 20px;

  &__qr-wrapper {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }

  .rounded-md {
    border-radius: 12px !important;
  }
}
</style>
