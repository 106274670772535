var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { "data-qa": "review_phone_confirmation" } },
    [
      _vm.userData.isPhoneConfirmedByCall
        ? _c(
            "VBtn",
            {
              staticClass: "mb-4",
              attrs: {
                block: "",
                depressed: "",
                color: "primary",
                "data-qa": "review_send_button",
              },
              on: { click: _vm.handleClickSendReview },
            },
            [
              _vm.isSomeImageSending
                ? _c("VProgressCircular", {
                    attrs: {
                      "data-qa": "review_send_button_progress",
                      color: "white",
                      size: "22",
                      width: "3",
                      indeterminate: "",
                    },
                  })
                : [_vm._v("\n      Отправить отзыв\n    ")],
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "ui-text ui-text_body-2 ui-kit-color-text-secondary text-center",
          attrs: { "data-qa": "review_send_policy" },
        },
        [
          _vm.commonData.isRussiaDomain
            ? [
                _vm._v("\n      Отправляя отзыв, вы даёте согласие на\n      "),
                _c(
                  "a",
                  {
                    staticClass:
                      "b-link b-link_underline_hover b-link_color_neur-blue d-inline",
                    attrs: { target: "_blank", href: "/info/privacy-policy/" },
                  },
                  [_vm._v("обработку персональных данных")]
                ),
                _vm._v(", принимаете\n      "),
                _c(
                  "a",
                  {
                    staticClass:
                      "b-link b-link_underline_hover b-link_color_neur-blue d-inline",
                    attrs: { target: "_blank", href: "/info/terms-of-use/" },
                  },
                  [_vm._v("пользовательское соглашение")]
                ),
                _vm._v(" и\n      "),
                _vm.isSelectedLpuAddressInPrs
                  ? [
                      _vm._v("\n        условия программ\n        "),
                      _c(
                        "a",
                        {
                          staticClass:
                            "b-link b-link_underline_hover b-link_color_neur-blue d-inline",
                          attrs: {
                            target: "_blank",
                            href: "/info/authors-protection/",
                          },
                        },
                        [_vm._v("«Защита авторов отзывов»")]
                      ),
                      _vm._v("\n        и\n        "),
                      _c(
                        "a",
                        {
                          staticClass:
                            "b-link b-link_underline_hover b-link_color_neur-blue d-inline",
                          attrs: {
                            target: "_blank",
                            href: "/static/_v1/pd/documents/pdf/Регламент_Сила_отзыва_для_пациентов.pdf",
                          },
                        },
                        [_vm._v("«Сила отзыва»")]
                      ),
                      _vm._v(",\n      "),
                    ]
                  : [
                      _c(
                        "a",
                        {
                          staticClass:
                            "b-link b-link_underline_hover b-link_color_neur-blue d-inline",
                          attrs: {
                            target: "_blank",
                            href: "/info/authors-protection/",
                          },
                        },
                        [_vm._v("условия программы защиты авторов отзывов")]
                      ),
                      _vm._v(",\n      "),
                    ],
                _vm._v(
                  "\n\n      а также подтверждаете получение согласия третьих лиц на публикацию их персональных данных\n    "
                ),
              ]
            : [
                _vm._v("\n      Отправляя отзыв, вы даёте согласие на\n      "),
                _c(
                  "a",
                  {
                    staticClass:
                      "b-link b-link_underline_hover b-link_color_neur-blue d-inline",
                    attrs: { target: "_blank", href: "/info/privacy-policy/" },
                  },
                  [_vm._v("обработку персональных данных")]
                ),
                _vm._v(" и принимаете\n      "),
                _c(
                  "a",
                  {
                    staticClass:
                      "b-link b-link_underline_hover b-link_color_neur-blue d-inline",
                    attrs: { target: "_blank", href: "/info/terms-of-use/" },
                  },
                  [_vm._v("пользовательское соглашение")]
                ),
                _vm._v(
                  ", а также подтверждаете получение\n      согласия третьих лиц на публикацию их персональных данных\n    "
                ),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }